
import { useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./LanguageSwitcher.module.scss";
import classNames from "classnames";
// List of supported languages
const languages = ["ru", "en"];

interface DropdownLanguageProps extends React.HTMLAttributes<HTMLSelectElement> {
}

export const DropdownLanguage = (
  {
    className,
    ...rest
  } : DropdownLanguageProps
) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || "ru");

  const handleLangChange = evt => {
    const lang = evt.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };



  return (
    <select onChange={handleLangChange} value={language} className={classNames(style["select"], className)} {...rest}>
      {languages.map((language) =>
        <option key={language} value={language}>{language.toUpperCase()}</option>
      )}
    </select>
  );
};