import { HTMLProps } from "react";
import { useTranslation, } from "react-i18next";
import ruLogo from "../../assets/images/app-store-badge/app-store-badge-ru.svg";
import enLogo from "../../assets/images/app-store-badge/app-store-badge-en.svg";
interface AppStoreButtonProps extends HTMLProps<HTMLDivElement> {
    link?: string
}

export function AppStoreButton(props: AppStoreButtonProps) {
    const { i18n } = useTranslation(); 

    const { link, ...rest } = props;

    const version = i18n.language === "ru" ? "ru" : "en";


    return (
        <a style={{
            width: version === "ru" ? "150px" : "131px",
            marginTop: version === "ru" ? "0px" : "-10px",
        }}  href={link} target="_blank" rel="noreferrer">
            <div {...rest}>
                <img style={{
                    width: "100%",
                }} src={i18n.language === "ru" ? ruLogo : enLogo} alt="Google Play" />
            </div>
        </a>
    )

}