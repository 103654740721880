import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import styles from "./ScrollBar.module.scss";
interface ScrollBarProps {
  children: any;
}
export default function ScrollBar(props: ScrollBarProps) {
  let scrollref = useRef<Scrollbars>(null);
  let [scrollingAvailable, setScrollingAvailable] = useState<boolean>(false);

  useEffect(() => {
    console.log(scrollref);
    if (scrollref.current) {
      const { scrollHeight, clientHeight } = scrollref.current.getValues();
      const nextIsScrollingAvailable = scrollHeight - clientHeight > 0;

      if (nextIsScrollingAvailable !== scrollingAvailable) {
        setScrollingAvailable(nextIsScrollingAvailable);
      }
      console.log(nextIsScrollingAvailable)
    }
  });

  return (
    <Scrollbars
      renderThumbVertical={renderThumb}
      renderTrackVertical={renderTrack}
      hideTracksWhenNotNeeded={true}
      ref={scrollref}
      renderView={props => <div {...props} className={scrollingAvailable? styles.contentPadding: ""} />}
    >

      {props.children}
    </Scrollbars>
  );
}

function renderThumb({ style, ...props }: any) {
  return <div className={styles.scrollThumb} style={{ ...style }} {...props} />;
}

function renderTrack({ style, ...props }: any) {
  return (
    <div
      className={styles.scrollVerticalTrack}
      style={{ ...style }}
      {...props}
    />
  );
}
