type Props = {
    children: React.ReactNode
}
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

export default function ModalPortal({ children }: Props) {
    const [mounted, setMounted] = useState(false)

   useEffect(() => {
      setMounted(true)

      return () => setMounted(false)
   }, [])

   
   return mounted
      ? createPortal(children, document.querySelector("#modalhost"))
      : null
}