import classNames from "classnames";
import Fuse from "fuse.js";
import { toast } from 'react-toastify';
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { GetCurrentUser, GetHistory, HistoryItem, User, SetArchived } from "@/api/data";
import {
  ArrowBottomSvg,
  ArrowSvg,
  ArrowTopSvg,
  IconsBoxUploadSvg,
  IconsCustomsSvg,
  IconsShipSvg,
  IconsUnloadingSvg,
  Inspection,
  Midk,
} from "@/assets/svg/icons";
import train from "@/assets/images/train.png";
import { Sidebar } from "@/components/Sidebar/Sidebar";
import styles from "./DashboardPage.module.scss";
import Arrow from "@/components/Arrow/Arrow";
import { PossibleFilters } from "@/components/QuickFilters/QuickFilters";
import NotificationWidget from "@/components/notifications/NotificationWidget";
import { format } from "date-fns";
import { useTranslation } from 'react-i18next';
import { SetStateAction, Dispatch } from "react"
import { ShowConfirmPopup } from "@/components/Popup/ConfirmPopup";

type SortState = {
  field: keyof HistoryItem;
  order: "ASC" | "DESC";
} | null;


// // Remove spaces from the string
// function removeSpaces(str: string) {
//   return str.replaceAll(/\s/g, "");
// }

function empty(item: any) {
  return (
    item == null ||
    item === undefined ||
    item === ""
    // removeSpaces(item) == ""
  )
}

export function DashboardPage() {
  const { t } = useTranslation();

  const [user, setUser] = useState<User | undefined>(undefined);

  const [filters, setFilters] = useState<typeof PossibleFilters>(PossibleFilters);

  const [isArchive, setIsArchive] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [search, setSearch] = useState("");

  const [data, setData] = useState<Array<HistoryItem>>([]);
  const [openRows, SetOpenRows] = useState<Array<number>>([]);

  const [sort, setSort] = useState<SortState>(null);
  // Index the items
  const fuse = useMemo(() => {
    return new Fuse(data, {
      keys: [
        "container_id",
        "dislocation",
        "conosoment_number",
        "client_number",
        "comment",
        "description",
        "sender_name",
        "destination_city",
        "destination_port",
        "request_number",
        "gtd_number",
      ],
      useExtendedSearch: true,
      location: 0,
      threshold: 0.1,
      distance: 0,
      ignoreLocation: true,
    });
  }, [data]);

  const navigate = useNavigate();
  const location = useLocation();

  const filteredItems = useMemo(() => {
    let filtered = data;

    // Filter by status
    filtered = filtered.filter((item) => item.archive == isArchive);

    if (search != "") {
      filtered = fuse.search(search).map((item) => item.item);
    }

    // Filter
    if (filters.length > 0 && filters.length < PossibleFilters.length) {
      filtered = filtered.filter((item) => {
        let shouldBeShown = false;
        filters.forEach(
          (filter) => (shouldBeShown = shouldBeShown || filter.filter(item))
        );
        return shouldBeShown;
      });
    }

    return filtered;
  }, [data, isArchive, search, filters]);

  // Sort
  const sortedItems = useMemo(() => {
    if (sort == null) {
      return filteredItems;
    }

    const sorted = [...filteredItems];

    const field = sort.field;

    if (sort.order === "ASC") {
      sorted.sort((a, b) => {
        // Send empty to the end of the list
        if (empty(a[field])) {
          return 1;
        }
        if (empty(b[field])) {
          return -1;
        }

        if (a[field]! > b[field]!) {
          return 1;
        } else if (a[field]! < b[field]!) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {

      sorted.sort((a, b) => {
        // Send empty to the end of the list
        if (empty(a[field])) {
          return 1;
        }
        if (empty(b[field])) {
          return -1;
        }

        if (a[field]! > b[field]!) {
          return -1;
        } else if (a[field]! < b[field]!) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return sorted;
  }, [filteredItems, sort]);

  const TableHeaderFields: Array<TableHeaderField> = [
    {
      name: t("dashboard.tableHeaders.requestNumber", "№ Заявки"),
      field: "request_number",
      sortable: true,
    },
    {
      name: t("dashboard.tableHeaders.clientNumber", "№ клиента"),
      field: "client_number",
      sortable: true,
    },
    {
      name: t("dashboard.tableHeaders.description", "Описание груза"),
      field: "description",
      sortable: true,
    },
    {
      name: t("dashboard.tableHeaders.senderName", "Отправитель"),
      field: "sender_name",
      sortable: true,
    },

    {
      name: t("dashboard.tableHeaders.containerId", "Номер КТК"),
      field: "container_id",
      sortable: true,
    },
    {
      name: t("dashboard.tableHeaders.dateActualForSite", "Готовность"),
      field: "readiness",
      sortable: true,
    },
    {
      name: t("dashboard.tableHeaders.dateVihodvmore", "Выход в море"),
      field: "date_vihodvmore",
      sortable: true,
    },
    {
      name: t("dashboard.tableHeaders.datePributievport", "Прибытие"),
      field: "date_pributievport",
      sortable: true,
    },
  ];

  // Check if user is logged in
  useEffect(() => {
    (async () => {
      const user = await GetCurrentUser();

      if (!user) {
        navigate("/login/" + location.search);
        return;
      }
      setUser(user);

      const response = await GetHistory();
      if (response) {
        setData(response);
        const params = new URLSearchParams(location.search);
        const search = params.get("s");
        const sortby = params.get("b");
        const sortorder = params.get("o");
        if (search) {
          setSearch(search);
        }
        // Restore sort
        if (sortby) {
          setSort({
            field: sortby as keyof HistoryItem,
            order: sortorder === "DESC" ? "DESC" : "ASC",
          });
        }
      }
    })();
  }, []);

  // Update the url with params
  useEffect(() => {

    const params = new URLSearchParams();
    if (sort) {
      params.set("b", sort.field)
      params.set("o", sort.order)
    }
    if (search) {
      params.set("s", search)
    }
    navigate("?" + params, {
      replace: true
    })
  }, [
    sort, filters, search
  ])

  return (
    <main className="main">
      <NotificationWidget />

      <Sidebar
        user={user}
        isArchive={isArchive}
        onArchiveChange={setIsArchive}
        searchValue={search}
        onSearchInput={setSearch}
        setFilters={setFilters}
        filters={filters}
        isOpen={sidebarActive}
        setIsOpen={setSidebarActive}
      />

      <div className={classNames(styles.content, {
        [styles.sidebarOpen]: sidebarActive
      })}>
        <table className={styles.TableContainer}>
          <TableHeader
            fields={TableHeaderFields}
            sort={sort}
            setSort={setSort}
            hasAnyRowsOpen={openRows.length > 0}
            toggleRows={(openAll) => {
              if (openAll) {
                SetOpenRows(filteredItems.map((item) => item.id));
              } else {
                SetOpenRows([]);
              }
            }}
          />
          <tbody className={styles.tableContent}>
            {sortedItems.map((item) => {
              return (
                <Row
                  key={item.id}
                  data={item}
                  isOpen={openRows.includes(item.id)}
                  setOpenRows={SetOpenRows}
                  setData={setData}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </main>
  );
}

interface TableHeaderField {
  name: string;
  field: keyof HistoryItem;
  sortable: boolean;
}
export function TableHeader({
  fields,
  sort,
  setSort,
  hasAnyRowsOpen,
  toggleRows,
}: {
  fields: Array<TableHeaderField>;
  sort: SortState;
  setSort: React.Dispatch<React.SetStateAction<SortState>>;

  hasAnyRowsOpen: boolean;
  toggleRows: (openAll: boolean) => void;
}) {
  return (
    <thead className={styles.header}>
      <tr>
        {/* TODO: Reimplement it */}
        <th
          align="center"
          onClick={() => {
            toggleRows(!hasAnyRowsOpen);
          }}
        >
          <span
            className={classNames(styles["openAllButton"], {
              [styles["openAllButtonActive"]]: hasAnyRowsOpen,
            })}
          >
            <ArrowSvg />
          </span>
        </th>

        {fields.map((field) => {
          return (
            <Fragment key={field.name}>
              <th align="left">{field.name}</th>
              <th align="right" className={styles.tableBorder}>
                {field.sortable && (
                  <span className={styles["sort-arrows"]}>
                    <ArrowTopSvg
                      className={
                        field.field === sort?.field && sort.order === "ASC"
                          ? styles["active"]
                          : ""
                      }
                      onClick={() => {
                        if (sort?.field === field.field && sort.order === "ASC") {
                          setSort(null);
                        } else {
                          setSort({
                            field: field.field,
                            order: "ASC",
                          });
                        }
                      }}
                    />
                    <ArrowBottomSvg
                      className={
                        field.field === sort?.field && sort.order === "DESC"
                          ? styles["active"]
                          : ""
                      }
                      onClick={() => {
                        if (
                          sort?.field === field.field &&
                          sort.order === "DESC"
                        ) {
                          setSort(null);
                        } else {
                          setSort({
                            field: field.field,
                            order: "DESC",
                          });
                        }
                      }}
                    />
                  </span>
                )}
              </th>
            </Fragment>
          );
        })}
      </tr>
    </thead>
  );
}

function fdate(date?: Date | null) {
  if (date == null || date === undefined) {
    return "";
  }
  if (date.toString() === "Invalid Date") {
    return "";
  }
  return format(date, "yyyy-MM-dd");
}

const Row = memo(({ data, isOpen, setOpenRows, setData }: {
  data: HistoryItem,
  isOpen: boolean,
  setOpenRows: React.Dispatch<React.SetStateAction<number[]>>
  setData: Dispatch<SetStateAction<HistoryItem[]>>;
}) => {
  const item = data;

  const { t } = useTranslation();

  return (
    <Fragment>
      <tr
        className={classNames(styles.tableRow, {
          [styles.active]: isOpen,
        })}
        onClick={() => {
          setOpenRows((openRows) => {
            if (openRows.includes(item.id)) {
              return openRows.filter((n) => n !== item.id);
            } else {
              return [...openRows, item.id];
            }
          });
        }}
      >
        <td>
          {/* arrow svg icons table opening   */}
          <Arrow isOpen={isOpen} />
        </td>
        {/* номер транзит */}
        <td colSpan={2}>{item.request_number}</td>
        {/* номер клиента */}
        <td colSpan={2}>{item.client_number}</td>
        {/* описание груза */}
        <td colSpan={2}>{item.description}</td>
        {/* отправитель */}
        <td colSpan={2}>{item.sender_name}</td>
        {/* номер ктк */}
        <td colSpan={2}>{item.container_id}</td>
        {/* готовность */}
        <td colSpan={2} style={{ textAlign: "center" }}>
          {fdate(item.readiness)}
        </td>
        {/* выход в море */}
        <td colSpan={2} style={{ textAlign: "center" }}>
          {fdate(item.date_vihodvmore)}
          {/* <i className="tpp-icons">customs</i> */}
        </td>
        {/* прибытие */}
        <td colSpan={2} style={{ textAlign: "center" }}>
          {fdate(item.date_pributievport)}
          {/* <i className="tpp-icons">customs</i> */}
        </td>
      </tr>

      <tr
        className="tdet"
        style={{ display: isOpen ? "table-row" : "none" }}
      >
        {isOpen && (
          <td colSpan={18}>
            <table width="100%">
              <tbody>
                <tr>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.placeFrom", "Место отправления")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {item.place_from || "-"}
                  </td>

                  <td valign="bottom" align="left">
                    <div
                      dangerouslySetInnerHTML={
                        { __html: t("dashboard.detailHeaders.dateVihodvmore", "Выход в&nbsp;море") + ":" }
                      }
                    />
                  </td>
                  <td className={styles.rightTd}>
                    {fdate(item.date_vihodvmore) || "-"}
                  </td>

                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.dateOtpravkapojd", "Отправка ЖД")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {fdate(item.date_otpravkapojd) || "-"}
                  </td>
                </tr>
                <tr>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.destinationPort", "Порт прибытия")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {item.destination_port || "-"}
                  </td>

                  <td valign="bottom" align="left">
                    <div dangerouslySetInnerHTML={
                      { __html: t("dashboard.detailHeaders.portArrivalDate", "Прибытие в&nbsp;порт") + ":" }
                    } />
                  </td>
                  <td className={styles.rightTd}>
                    {fdate(item.date_pributievport) || "-"}
                  </td>

                  <td valign="bottom" align="left">
                    <div
                      dangerouslySetInnerHTML={
                        { __html: t("dashboard.detailHeaders.dateArrivedToStation", "Прибытие на&nbsp;станцию") + ":" }
                      }
                    />
                  </td>
                  <td className={styles.rightTd}>
                    {fdate(item.date_arrived_to_station) || "-"}
                  </td>
                </tr>
                <tr>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.destinationCity", "Город назначения")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {item.destination_city || "-"}
                  </td>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.dateKonosament", "Получение коносамента")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {fdate(item.date_konosament) || "-"}
                  </td>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.dateVigruzkanasklad", "Выгрузка")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {fdate(item.date_vigruzkanasklad) || "-"}
                  </td>
                </tr>
                <tr>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.gtdNumber", "Номер ДТ")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {item.gtd_number || "-"}
                  </td>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.datePodachagtd", "Подача ДТ")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {fdate(item.date_podachagtd) || "-"}
                  </td>
                  {item.daysOnTheRoad ?
                    (<td
                      className={styles.rightTd}
                      rowSpan={2}
                      colSpan={2}
                      align="center"
                      valign="bottom"
                    >
                      <span dangerouslySetInnerHTML={{
                        __html: t("dashboard.detailHeaders.daysOnTheRoad", {
                          count: item.daysOnTheRoad ?? 0,
                        })

                      }} />
                    </td>) :
                    (<td></td>)
                  }

                </tr>
                <tr>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.restrictedRetrieval", "Запрет")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {item.restricted_retrieval === 0 ? "Нет" : "Да"}
                  </td>
                  <td valign="bottom" align="left">
                    <div>{t("dashboard.detailHeaders.dateVipuskgtd", "Выпуск")}:</div>
                  </td>
                  <td className={styles.rightTd}>
                    {fdate(item.date_vipuskgtd) || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%">
              <tbody className={styles.containerTflow}>
                <tr className="tflow">
                  <td
                    className={classNames({
                      active: item.readiness,
                    })}
                  >
                    <div className="iconContainer">
                      <IconsBoxUploadSvg />
                    </div>

                    <div className="data">
                      {fdate(item.readiness)}
                      <br />
                      {t("dashboard.statusCaptions.cargoReadiness", "Готовность груза")}
                    </div>
                  </td>
                  <td
                    className={classNames({
                      active: item.date_vihodvmore !== null,
                    })}
                  >
                    <div className="iconContainer">
                      <IconsShipSvg />
                    </div>

                    <div className="data">
                      {fdate(item.date_vihodvmore)}
                      <br />
                      <span
                        dangerouslySetInnerHTML={
                          { __html: t("dashboard.statusCaptions.dateExitToSea", "Выход в&nbsp;море") }
                        }
                      />
                    </div>
                  </td>
                  <td
                    className={classNames({
                      active: fdate(item.date_pributievport),
                    })}
                  >
                    <div className="iconContainer">
                      <IconsShipSvg />
                    </div>

                    <div className="data">
                      {fdate(item.date_pributievport)}
                      <br />
                      <span
                        dangerouslySetInnerHTML={
                          { __html: t("dashboard.statusCaptions.portArrivalDate", "Прибытие в&nbsp;порт") }
                        }
                      />
                    </div>

                  </td>
                  <td
                    className={classNames({
                      active: fdate(item.date_podachagtd),
                    })}
                  >
                    <div className="iconContainer">
                      <IconsCustomsSvg />
                    </div>

                    <div className="data">
                      {fdate(item.date_podachagtd)}
                      <br />
                      {t("dashboard.statusCaptions.dateOfDT", "Подача ДТ")}
                    </div>
                  </td>
                  {item.midk === 1 ? (
                    <td
                      className={classNames({
                        active: item.midk,
                      })}
                    >
                      <div className="iconContainer">
                        <Midk />
                      </div>

                      <div className="data">
                        {/* {item.midk} */}
                        <br />
                        {t("dashboard.statusCaptions.midk", "МИДК")}
                      </div>

                    </td>) : <td></td>}
                  {item.inspection === 1 ? (<td
                    className={classNames({
                      active: item.inspection,
                    })}
                  >
                    <div className="iconContainer">
                      <Inspection />
                    </div>

                    <div className="data">
                      {/* {item.inspection} */}
                      <br />
                      {t("dashboard.statusCaptions.inspection", "Досмотр")}
                    </div>
                  </td>) : <td></td>}

                  <td
                    className={classNames({
                      active: fdate(item.date_vipuskgtd),
                    })}
                  >
                    <div className="iconContainer">
                      <IconsCustomsSvg />
                    </div>

                    <div className="data">
                      {fdate(item.date_vipuskgtd)}
                      <br />
                      {t("dashboard.statusCaptions.dateOfExit", "Выпуск")}
                    </div>
                  </td>
                  <td
                    className={classNames({
                      active: wasOnTheRoad(item),
                    })}
                  >
                    <div className="iconContainer">
                      <img src={train}></img>
                    </div>

                    <div className="data">
                      {fdate(item.date_otpravkapojd)}
                      <br />
                      {t("dashboard.statusCaptions.dateSentToRailroad", "Отправка ЖД")}
                    </div>
                  </td>
                  {wasOnTheRoad(item) && item.distance !== 0 && <td
                    className={classNames("disl", "active")}
                  >
                    <div className="iconContainer">
                      <img src={train} style={{ opacity: 0 }}></img>
                    </div>

                    <div className="data">
                      {item?.dislocation ?? "-"}
                      <br />
                      {item?.distance?.toLocaleString('ru') ?? "-"}
                    </div>
                  </td>}

                  {/* <td className="disl">
            <i className="tpp-icons">train</i>
            <div>&nbsp;</div>
          </td> */}
                  <td
                    className={classNames({
                      active: fdate(item.date_arrived_to_station),
                    })}
                  >
                    <div className="iconContainer">
                      <img src={train}></img>
                    </div>

                    <div className="data">
                      {fdate(item.date_arrived_to_station)}
                      <br />
                      <span
                        dangerouslySetInnerHTML={
                          { __html: t("dashboard.statusCaptions.dateArrivedToStation", "Прибытие на&nbsp;станцию") }
                        }
                      />
                    </div>
                  </td>
                  <td
                    className={classNames({
                      active: fdate(item.date_vigruzkanasklad),
                    })}
                  >
                    <div className="iconContainer">
                      <IconsUnloadingSvg />
                    </div>

                    <div className="data">
                      {fdate(item.date_vigruzkanasklad)}
                      <br />
                      {t("dashboard.statusCaptions.dateOfUnloading", "Выгрузка")}
                    </div>
                  </td>
                </tr>
              </tbody>


            </table>
            <div style={{
              width: "100%",
              display: "flex",
            }}>
              {!item.archive && (
                <button style={{
                  backgroundColor: item.client_archived ? "#00a8ff" : "#007bff",
                  color: "white",
                  border: "none",
                  padding: "10px",
                  cursor: "pointer",
                }} onClick={async () => {
                  const sure = await ShowConfirmPopup({
                    cancelText: t("dashboard.cancelButton", "Отмена"),
                    okText: t("dashboard.okButton", "Ок"),
                    text: item.client_archived ? t("dashboard.unArchiveConfirm", "Вы уверены, что хотите вернуть из архива?") : t("dashboard.archiveConfirm", "Вы уверены, что хотите отправить в архив?"),
                    title: t("dashboard.confirmTitle", "Подтверждение"),
                    closeOnBackgroundClick: true,
                  })

                  if (!sure) return;

                  const result = await SetArchived(item.id, !item.client_archived);
                  const response = await GetHistory();
                  if (response) {
                    setData(response);
                  }
                  toast(result ? t("dashboard.toast.success", "Успешно") : t("dashboard.toast.error", "Ошибка"), {
                    type: "success"
                  })
                }}>
                  {item.client_archived ? t("dashboard.unArchiveButton", "Вернуть из архива") : ""}
                  {item.client_archived ? "" : t("dashboard.archiveButton", "Отправить в архив")}
                </button>
              )}

            </div>
          </td>
        )}
      </tr>
    </Fragment>
  )
});

/**
 * Check if the item was on the road
 * @param item 
 * @returns 
 */
function wasOnTheRoad(item: HistoryItem) {
  // If date or dislocation is set then it was on the road
  return fdate(item.date_otpravkapojd) || (item.dislocation && item.dislocation != "")
}