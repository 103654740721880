import React, { useEffect, useState } from "react";
import { Routes } from "react-router";
import { Route, BrowserRouter } from "react-router-dom";
import { DashboardPage } from "./pages/DashboardPage";
import { LoginPage } from "./pages/LoginPage";

import { ToastContainer } from "react-toastify";
import { GetCurrentUser } from "./api/data";
import { useLocation, useNavigate } from "react-router";
import ModalPortal from "./components/Popup/ModalPortal";
import PopupMessageHost from "./components/Popup/ConfirmPopup";

function App() {

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  // Check if user is logged in
  useEffect(() => {
    (async () => {
      const user = await GetCurrentUser();

      if (!user) {
        navigate("/login/" + location.search);
        setLoading(false);
        return;
      }
      setLoading(false);
      
    })();
  }, []);



  if (loading) {
    return <div></div>
  }

  return (
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/login/" element={<LoginPage />} />
    </Routes>
  );
}

function AppWrapper() {
  return (
    <div>
      <div className="app">
        <BrowserRouter basename={"/"}>
          <App/>
        </BrowserRouter>
      </div>
      <ToastContainer />
      <ModalPortal>
        <PopupMessageHost />
      </ModalPortal>
    </div>
  );
}

export default AppWrapper;
