import { HTMLProps } from "react";
import { useTranslation, } from "react-i18next";
import ruLogo from "../../assets/images/play-store-badge/google-play-badge-ru.png";
import enLogo from "../../assets/images/play-store-badge/google-play-badge-en.png";
interface PlayButtonProps extends HTMLProps<HTMLDivElement> {
    link?: string
}

export function PlayButton(props: PlayButtonProps) {
    const { i18n } = useTranslation();

    const { link, ...rest } = props;

    return (
        <a style={{
            width: "150px",
        }} href={link} target="_blank" rel="noreferrer">
            <div  {...rest}>
                <img style={{
                    width: "100%",
                }} src={i18n.language === "ru" ? ruLogo : enLogo} alt="Google Play" />
            </div>
        </a>
    )

}