import { ArrowSvg } from "../../assets/svg/icons";
import classNames from "classnames";
import styles from "./Arrow.module.scss";

const Arrow = ({ isOpen }: { isOpen: boolean }) => {
    return (
        <span className={classNames(styles.iconButton, {
            [styles.removeAnimation]: !isOpen,
            [styles.animation]: isOpen,
        })}>
            <ArrowSvg />
        </span>
    )
}

export default Arrow;