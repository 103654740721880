import { parse } from "date-fns";

const apiPath = import.meta.env.VITE_API_PATH ?? "/cabinet/";

/**
 * Authenticates the user
 * @param login
 * @param password
 */
export async function Authenticate(login: string, password: string) {
  const params = new URLSearchParams({
    login,
    password,
  });

  const response = await fetch(apiPath + "user/auth?" + params, {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json: {
      status: string;
      user_id: string;
    } = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("user not found");
    return null;
  }
}

/**
 * Logout the user
 * @param login
 * @param password
 */
export async function Logout() {
  const response = await fetch(apiPath + "user/logout", {
    method: "POST",
    credentials: "include"
  });

  return response.ok;
}

export interface User {
  companyName: string;
  created_at: string;
  id: number;
  login: string;
  uniqueKey: string;
  updateDate: string;
  updated_at: string;
  forwarder_email: string;

  manager_name: string;
  manager_email: string;
  manager_phone_number: string;

  director_name: string;
  director_email: string;
  director_phone_number: string;
}

/**
 * Gets current user
 * @param login
 * @param password
 */
export async function GetCurrentUser() {
  const response = await fetch(apiPath + "user/get", {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json: User = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("user not found");
    return undefined;
  } else {
    return undefined;
  }
}

export interface HistoryItemRaw {
  /**
   * ID юзера
   */
  user_id: number;

  actual: string;
  archive: boolean;
  /**
   * Manually archived
   */
  client_archived: boolean;
  client_number: string;
  comment: string;
  conosoment_number: string;
  container_id: string;
  container_type: string;
  created_at: string;

  date_actual_for_site: string;
  date_arrived_to_station: string;
  date_konosament: string;
  date_otpravkapojd: string;
  date_podachagtd: string;
  date_pributievport: string;
  date_vigruzkanasklad: string;
  date_vihodvmore: string;
  date_vipuskgtd: string;

  description: string;
  destination_city: string;
  destination_port: string;
  dislocation: string;
  distance: number;
  gtd_number: string;
  id: number;
  inspection: 1|0;
  midk: 1|0;
  packing_date: string;
  place_from: string;
  queue_id: string;
  readiness: string;
  request_number: string;
  restricted_retrieval: number;
  sender_name: string;
  skvoznoiservis: string;
  status: string;
  tamojenniykontrol: string;
  uniqueKey: string;
  updated_at: string;
  daysOnTheRoad: number;
}

export interface HistoryItem {
  /**
   * ID юзера
   */
  user_id: number;

  actual: string;
  archive: boolean;
  client_archived: boolean;
  client_number: string;
  comment: string;
  conosoment_number: string;
  container_id: string;
  container_type: string;
  created_at: Date;

  date_actual_for_site?: Date;
  date_arrived_to_station?: Date;
  date_konosament?: Date;
  date_otpravkapojd?: Date;
  date_podachagtd?: Date;
  date_pributievport?: Date;
  date_vigruzkanasklad?: Date;
  date_vihodvmore?: Date;
  date_vipuskgtd?: Date;
  readiness?: Date;

  description: string;
  destination_city: string;
  destination_port: string;
  dislocation: string;
  distance: number;
  gtd_number: string;
  id: number;
  inspection: 1|0;
  midk: 1|0;
  packing_date: string;
  place_from: string;
  queue_id: string;
  request_number: string;
  restricted_retrieval: number;
  sender_name: string;
  skvoznoiservis: string;
  status: string;
  tamojenniykontrol: string;
  uniqueKey: string;
  updated_at?: Date ;
  daysOnTheRoad: number;

  // Link to the raw data
  raw: HistoryItemRaw;
}

function isEmpty(str?: string) {
  return !str || str === "";
}

function parse1CDate(str: string) {
  if (isEmpty(str)) {
    return undefined;
  } else {
    const date = parse(str, "dd.MM.yyyy", new Date());
    return date;
  }
}

function parse1CDateTime(str: string) {
  if (isEmpty(str)) {
    return undefined;
  } else {
    const date = parse(str, "dd.MM.yyyy HH:mm:ss", new Date());
    return date;
  }
}

export async function SetArchived(id: number, isArchived) {
  const endpoint = isArchived ? "archive" : "unarchive";
  const response = await fetch(apiPath + `history/${id}/${endpoint}/`, {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json: HistoryItemRaw = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("user not found");
    return null;
  }
}


export async function GetHistory() {
  const params = new URLSearchParams({});

  const response = await fetch(apiPath + "history/get?" + params, {
    method: "GET",
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<HistoryItemRaw> = await response.json();

    const result: Array<HistoryItem> = json.map((item) => {
      const result: HistoryItem = {
        ...item,
        created_at: new Date(item.created_at),
        date_actual_for_site: parse1CDateTime(item.date_actual_for_site),
        date_arrived_to_station: parse1CDate(item.date_arrived_to_station),
        date_konosament: parse1CDate(item.date_konosament),
        date_otpravkapojd: parse1CDate(item.date_otpravkapojd),
        date_podachagtd: parse1CDate(item.date_podachagtd),
        date_pributievport: parse1CDate(item.date_pributievport),
        date_vigruzkanasklad: parse1CDate(item.date_vigruzkanasklad),
        date_vihodvmore: parse1CDate(item.date_vihodvmore),
        date_vipuskgtd: parse1CDate(item.date_vipuskgtd),
        updated_at: parse1CDate(item.updated_at),
        readiness: parse1CDate(item.readiness),
        daysOnTheRoad: item.daysOnTheRoad ?? 0,
        raw: item,
      };
      
      return result;

    });
    return result;
  } else if (response.status === 401) {
    console.log("user not found");
    return null;
  }
}

export async function getCurrencies() {
  const response = await fetch(apiPath + "getCurrencies", {
    method: "GET"
  });

  if (response.ok) {
    const json: {
      eur: number;
      usd: number;
    } = await response.json();
    return json;
  } else {
    return undefined;
  }
}




export const NotificationStatusMap = {
  "delivered" : "Доставлен",
  "onTrainStation" : "Прибытие на станцию",
  "containerOnRails" : "Отправка по ЖД",
  "waitingForRails" : "Выпуск с таможни",
  "customsClearance" : "Подача документов на таможне",
  "arrivalInPort" : "Прибытие в порт",
  "wentToSea" : "Выход в море",
  "uponTheSender" : "У отправителя",
  "custom" : "Custom"
}

export interface Notification {
  /**
   * 
   */
  created_at: string
  dateActualForSite: string
  id: number
  requestId?: string
  status: keyof typeof NotificationStatusMap
  updated_at: string
  userId: number
  sender_name?: string
  text?: string
  /**
   * Notification status, 1 - true 0 - false
   */
  viewed: 1|0
}

export async function GetNotifications() {
  const params = new URLSearchParams({});

  const response = await fetch(apiPath + "notification/get?" + params, {
    method: "GET",
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<Notification> = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("No notifications");
    return null;
  }
}

export async function SetNotificationRead(notificationId: number) {
  const response = await fetch(apiPath + `notification/read/${notificationId}`, {
    method: "GET",
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<Notification> = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("No notifications");
    return null;
  }
}

export interface ForwarderEmailI {
  id: string;
  email: string;
}

export async function ForwarderEmail(email: string) {
  const params = new URLSearchParams({
    email,
  });

  const response = await fetch(apiPath + "user/setForwarderEmail?" + params, {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json:ForwarderEmailI = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("not found");
    return null;
  }
}

export interface SendFeedbackI {
  email: string;
  director: string;
  manager: string;
  company: string;
  text: string;
}


export async function SendFeedback(email: string, company: string, text: string) {
  const params = new URLSearchParams({
    email,
    company,
    text
  });

  const response = await fetch(apiPath + "sendFeedback?" + params, {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json:SendFeedbackI = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("not found");
    return null;
  }
}



export async function DelNotification(id: Array<number>) {

  const response = await fetch(apiPath + "notification/notActual", {
    method: "POST",
    body: JSON.stringify(id),
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<number> = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("not found");
    return [];
  }
  return []
}

export async function ReadNotification(id: Array<number>) {

  const response = await fetch(apiPath + "notification/read", {
    method: "POST",
    body: JSON.stringify(id),
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<number> = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("not found");
    return [];
  }
  return []
}