import { MouseEventHandler, useState } from "react";
import styles from "./ContactButton.module.scss";
import classNames from "classnames"
import Arrow from "../Arrow/Arrow";

interface ContactButtonProps {
    title?: string,
    name?: string,
    email?: string,
    phone?: string
}

const ContactButton = ({title, name, email, phone}:ContactButtonProps) => {

    const [open, setOpen] = useState<boolean>(false);

    const switchOpen = (e: any) => {
        setOpen(!open);
    }


    return <>
        <div className={styles['contact']}>
            <div className={classNames(styles['contact__button'], {[styles['contact__button_opened']] : open})}>
                <div onClick={switchOpen} className={styles['contact__button_title']}>
                    <span>{title}</span>
                    <Arrow isOpen={open}/>
                </div>
                {open && <>
                    <div className={styles['contact__button_content']}>
                        <span>{name}</span>
                        <span>{email} {phone}</span>
                    </div>
                </>}
            </div>
        </div>
    </>
};

export default ContactButton;