import clsx from "clsx";
import { create } from 'zustand'
import BaseModal from "./BaseModal";
import styles from "./ConfirmPopup.module.scss";

const usePopupStore = create<
    {
        show: boolean;
        title?: string;
        text: string | JSX.Element;
        ok?: () => void;
        okText?: string;
        cancel?: () => void;
        cancelText?: string;
        showPopup: (props: Partial<Exclude<PopupErrorProps, ["show", "ok", "cancel"]>>) => void;
        hidePopup: () => void;
        closeOnEscape?: boolean;
        closeOnBackgroundClick?: boolean;
        hideCancel?: boolean;
    }
>((set) => ({
    show: false,
    text: "",
    title: "",
    ok: () => { },
    okText: "Ок",
    cancel: () => { },
    cancelText: "Отмена",
    showPopup: (props: Partial<Exclude<PopupErrorProps, ["show", "ok", "cancel"]>>) => set(() => ({
        show: true,
        title: props.title,
        text: props.text,
        ok: props.ok,
        okText: props.okText,
        cancel: props.cancel,
        cancelText: props.cancelText,
        closeOnBackgroundClick: props.closeOnBackgroundClick,
        closeOnEscape: props.closeOnEscape,
        hideCancel: props.hideCancel,
    })),
    hidePopup: () => set(() => ({
        show: false,
        title: "",
        text: "",
        ok: () => { },
        okText: "Ок",
        cancel: () => { },
        cancelText: "Отмена",
    })),
}))

export function ShowConfirmPopup(props: Partial<Exclude<PopupErrorProps, ["show", "ok", "cancel"]>>): Promise<boolean> {
    return new Promise((resolve, reject) => {
        usePopupStore.getState().showPopup({
            ...props,
            ok: () => {
                usePopupStore.getState().hidePopup();
                resolve(true);
            },
            cancel: () => {
                usePopupStore.getState().hidePopup();
                resolve(false);
            }
        });
    })
}



/**
 * Модальное окно авторизации
 * @returns 
 */
export default function PopupMessageHost() {
    const popupState = usePopupStore();

    return (
        <BaseModal
            show={popupState.show}
            onCloseClick={() => {
                popupState.hidePopup();
                popupState.cancel?.();
            }}
            onBackgroundClick={() => {
                if (popupState.closeOnBackgroundClick) {
                    popupState.hidePopup();
                    popupState.cancel?.();
                }
            }}
            onEscape={() => {
                popupState.hidePopup();
                popupState.cancel?.();
            }}
            showCloseButton={true}
        >
            <div className={styles.title}>{popupState.title || "Сообщение"}</div>
            <div className={styles.text}>{popupState.text || "Текст ошибки"}</div>
            <div className={styles.buttonsContainer}>
                {!popupState.hideCancel && popupState.cancel && <button className={clsx(styles.button, styles.cancel)} tabIndex={0} onClick={() => {
                    popupState.cancel?.();

                }}> {popupState.cancelText || "Отмена"}</button>
                }
                <button className={clsx(styles.button, styles.ok)} tabIndex={1} onClick={() => {
                    popupState.ok?.();
                }}>{popupState.okText || "Ок"}</button>
            </div>
        </BaseModal>
    )
}

interface PopupErrorProps {
    title?: string;
    text: string | JSX.Element
    ok?: () => void;
    okText?: string;
    cancel?: () => void;
    cancelText?: string;
    closeOnBackgroundClick?: boolean;
    closeOnEscape?: boolean;
    hideCancel?: boolean;
}
