import { useState } from "react";
import styles from "./ContactForm.module.scss";
import Modal from "react-modal";
import { ContactPopupArrow, IconsClose } from "../../assets/svg/icons";
import { SendFeedback } from "../../api/data";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

Modal.setAppElement('#root');

export default function ContactForm() {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }


  const processForm: React.FormEventHandler<HTMLFormElement> = async function (e) {

    e.preventDefault();

    const form = e.currentTarget;
    const formdata = new FormData(form);
    const email = formdata.get("email");
    const company = formdata.get("company");
    const text = formdata.get("text");


    if (!(email)) {
      return;
    }
    try {
      const response = await SendFeedback(email as string, company as string, text as string);
      if (response) {
        closeModal()
        toast(t("contactForm.toast.success", "Форма успешно отправлена."), {
          type: "success"
        })
      } else {
        toast(t("contactForm.toast.error", "Ошибка при отправке формы."), {
          type: "error"
        })
      }
    } catch (error) {
      toast(t("contactForm.toast.error", "Ошибка при отправке формы."), {
        type: "error"
      })
    }
    
  }



  const form = (
    <div className={styles.container}>
      <form onSubmit={processForm}>
        <div className={styles.control}>
          <span className={styles.controlTitle}>E-mail:</span>
          <input type="email"
            required
            name={"email"}
            placeholder={t("contactForm.input.emailPlaceholder", "Введите e-mail компании") + "*"}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
        </div>
        <div className={styles.control}>
          <span className={styles.controlTitle}>{t("contactForm.input.companyNameTitle", "Компания")}:</span>
          <input type="text"
            required
            name={"company"}
            placeholder={t("contactForm.input.companyNamePlaceholder", "Введите название компании") + "*"}
            minLength={2}
          />
        </div>
        <textarea name="text" required minLength={2} placeholder={t("contactForm.input.messagePlaceholder", "Введите текст сообщения") + "*"}></textarea>
        <div className={styles.buttons}>
          <button type="reset">{t("contactForm.clearButton", "Очистить")}</button>
          <button type="submit">{t("contactForm.sendButton", "Отправить")}</button>
        </div>
      </form>
    </div>
  );

  return (
    <>
      <div className={styles["contact-container"]}>
        <button onClick={openModal} className={styles["popup-open-button"]}>
          <span>{t("contactForm.modalTitle", "Обратная связь")}</span><ContactPopupArrow />
        </button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          className={styles["modal-style"]}
          overlayClassName={styles["overlay-style"]}
          contentLabel="Обратная связь"
        >
          <div style={{ position: "relative" }}>
            <div className={styles["close-button"]} onClick={closeModal}>
              <IconsClose />
            </div>
            {form}
          </div>
        </Modal>
      </div>
    </>
  );
}
