import { useNavigate } from "react-router";
import { Logout, User } from "../../api/data";
import styles from "./Sidebar.module.scss";
import { FaCheckSquare, FaSearch } from "react-icons/fa";
import { RadioSwitcher } from "../RadioSwitcher/RadioSwitcher";
import { SearchPrompt } from "../SearchPrompt/SearchPrompt";
import { useEffect, useState } from "react";
import { PossibleFilters, QuickFilters } from "../QuickFilters/QuickFilters";
import CurrencyWidget from "../CurrencyWidget/CurrencyWidget";
import ContactForm from "../contactForm/ContactForm";
import classNames from "classnames";
import ReactSidebar from "react-sidebar";
import { ArrowCircleRight } from "../../assets/svg/icons";
import ForwarderInput from "../ForwarderInput/ForwarderInput";
import ContactButton from "../ContactButton/ContactButton";
import { useTranslation } from "react-i18next";
import { ShowTranslations } from "@/util";
import { DropdownLanguage } from "../LanguageSwitcher/LanguageSwitcher";

interface SidebarProps {
  user?: User;
  onSearch?: () => any;
  onSearchInput?: (val: string) => any;
  searchValue?: string;
  isArchive?: boolean;
  onArchiveChange?: (val: boolean) => void;
  filters: typeof PossibleFilters;
  setFilters: React.Dispatch<React.SetStateAction<typeof PossibleFilters>>;

  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Sidebar({
  user,
  onSearch,
  onSearchInput,
  searchValue,
  onArchiveChange,
  isArchive,
  filters,
  setFilters,
  isOpen,
  setIsOpen,
}: SidebarProps) {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  let logout = async function () {
    await Logout();
    navigate("/login/");
  };

  const [wide, setWide] = useState(false);

  function checkSize() {
    const mql = window.matchMedia(`(min-width: 769px)`);
    // Set wide mode
    setWide(mql.matches);
  }

  useEffect(() => {
    checkSize();
    window.addEventListener("resize", checkSize);

    // cleanup this component
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  return (
    <div
      className={classNames(styles["sidebar-container"])}
    >
      <ReactSidebar
        sidebar={
          <div className={styles["sidebarContainer"]}>
            <div className={styles["sidebarFlexStart"]}>
              <div className={styles["welcome-message"]}>
                <div className={styles["welcome-message-text"]}>
                  {t("sidebar.welcome", "Добро пожаловать")}, <br />
                  <b>{user?.companyName}</b>
                </div>
                <DropdownLanguage className={styles["language-switcher"]} />
              </div>

              <CurrencyWidget />

              <RadioSwitcher onChange={onArchiveChange} value={isArchive} />
              <SearchPrompt
                onSearch={onSearch}
                onInput={onSearchInput}
                value={searchValue}
              />

              <div className={styles.containerQuickFilters}>
                <div className={styles.qfilters}>{t("sidebar.quickFilters.title", "Быстрые фильтры")}:</div>
                <QuickFilters filters={filters} setFilters={setFilters} />
              </div>

              <ContactForm />
              <ForwarderInput />
              <div style={{ marginTop: '22px' }}>
                <span className={styles["contactSpan"]}>{t("sidebar.contact.contactInfoTitle", "Контактная информация")}:</span>
              </div>

              <ContactButton title={t("sidebar.contact.mainManagerButton", "Ведущий менеджер")} name={user?.manager_name} email={user?.manager_email} phone={user?.manager_phone_number} />
              <ContactButton title={t("sidebar.contact.departmentManager", "Руководитель отдела")} name={user?.director_name} email={user?.director_email} phone={user?.director_phone_number} />
            </div>

            <div className={styles["sidebarFlexEnd"]}></div>
            <div className={styles["buttons-block"]}>
              {/* {import.meta.env.DEV && <button onClick={() => ShowTranslations(i18n)}>Show translations</button>} */}
              <button onClick={logout}>{t("sidebar.logoutButton", "Выход")}</button>
            </div>
          </div>
        }
        open={isOpen}
        onSetOpen={setIsOpen}
        sidebarClassName={styles["sidebar-content"]}
        overlayClassName={styles["overlay"]}
        contentClassName={styles["sidebar-opener"]}
        docked={wide}
        styles={{
          root: {
            // This is to fix the scrollbar not being touchable
            width: "300px",
          },
        }}
      >
        <div className={styles["mobile-sidebar"]} onClick={() => setIsOpen && setIsOpen(!isOpen)}>
          <ArrowCircleRight />
        </div>
      </ReactSidebar>

    </div>
  );
}
