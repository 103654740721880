import styles from "./SearchPrompt.module.scss"

import {FaSearch} from "react-icons/fa";
import { useTranslation } from "react-i18next";
interface SearchPromptProps {
    onInput?: (val: string) => any;
    value?: string;
    placeholder?: string;
    onSearch?: () => any
}



export function SearchPrompt (props: SearchPromptProps) {
    const processInput: React.FormEventHandler<HTMLInputElement> = function ( e) {
        const element = e.currentTarget;
        if (props.onInput) {
            props.onInput(element.value);
        }

    }

    const { t } = useTranslation();

    return (
        <div className={styles.search}>
            <input type="search" onInput={processInput} placeholder={props.placeholder || t("sidebar.searchPlaceHolder", "Поиск")} value={props.value}/>
            <button onClick={props.onSearch}>
                <FaSearch/>
            </button>
        </div>
    )
}
