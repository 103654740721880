import  styles from "./RadioSwitcher.module.scss"
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface  RadioSwitcherProps {
    onChange?: (val: boolean)=> void;
    value?: boolean
}



export function RadioSwitcher ({onChange, value}: RadioSwitcherProps) {
    const { t } = useTranslation();
    const toggle = function () {
        if (onChange) {
            onChange(!value)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.centered}>
                <span>{t("sidebar.ArchiveSwitcher.actual", "Текущие")}</span>
                <div
                    className={classNames(styles.switcher, {
                        [styles.on]: value
                    })}
                    onClick={toggle}/>

                <span>{t("sidebar.ArchiveSwitcher.archive", "Архив")}</span>
            </div>
        </div>
    )
}