import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Authenticate, GetCurrentUser } from '../api/data';
import { useTranslation } from 'react-i18next';

import styles from "./LoginPage.module.scss";
import { DropdownLanguage } from '@/components/LanguageSwitcher/LanguageSwitcher';
import { ShowTranslations } from '@/util';
import { PlayButton } from '@/components/StoreButtons/PlayButton';
import { AppStoreButton } from '@/components/StoreButtons/AppStoreButton';

export function LoginPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const processForm: React.FormEventHandler<HTMLFormElement> = async function (e) {
        e.preventDefault();

        const form = e.currentTarget;
        const formdata = new FormData(form)

        const login = formdata.get("login");
        const password = formdata.get("password");

        if (!(login && password)) {
            // TODO: Say something
            return;
        }

        const response = await Authenticate(login as string, password as string)

        if (response) {
            navigate("/" + location.search);
        } else {
            toast(t("errors.login.wrongPassword", "Неправильное имя пользователя или пароль."), {
                type: "error"
            })
        }


    }


    // Check if user is logged in then redirect to cabinet
    useEffect(() => {
        (async () => {
            const user = await GetCurrentUser();

            if (user) {
                navigate("/" + location.search);
                return;
            }
        })();
    }, []);


    return <div className="main">
        <div className={styles.container}>
            <div className="wr"></div>
            <div className="top">
                <div id="bcall">
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 1rem",
                    }}>
                        <span>{t("login.loginPage.title", "Вход")}</span>
                        <DropdownLanguage />
                    </div>
                    <form className="call_form" onSubmit={processForm}>
                        <input type="text" name="login" placeholder={t("login.loginPage.loginPlaceholder", "логин")} required />
                        <input type="password" name="password" placeholder={t("login.loginPage.passwordPlaceholder", "пароль")} required />
                        <button type="submit">{t("login.loginButtonText", "Войти")}</button>
                    </form>
                </div>
                {/* {import.meta.env.DEV && <button onClick={() => ShowTranslations(i18n)}>Show translations</button>} */}
            </div>
            <div style={{
                display: "flex",
                gap: "0px",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <PlayButton link='https://play.google.com/store/apps/details?id=tpp.parus.com' />
                <AppStoreButton link='https://apps.apple.com/app/парус-таможня-логистика/id1065047780' />
            </div>
        </div>
    </div>
}