import clsx from "clsx";
import { useEffect, useRef } from "react";
import FocusTrap from "focus-trap-react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import ModalPortal from "./ModalPortal";
import { animated, easings, useTransition } from "react-spring";
import styles from "./BaseModal.module.scss";
import { FaXmark } from "react-icons/fa6";

type Props = {
    show: boolean;
    children: React.ReactNode;
    onCloseClick?: () => void;
    onEscape?: () => void;
    onBackgroundClick?: () => void;
    className?: string;
    wrapperClassName?: string;
    showCloseButton?: boolean;
    /**
     * Disable focus trap for modals with no inputs
     */
    disableFocusTrap?: boolean;
}

/**
 * Basic modal component with escape and background click handlers
 * @param param0 
 * @returns 
 */
export default function BaseModal({
    show,
    children,
    onCloseClick,
    onEscape,
    onBackgroundClick,
    className,
    wrapperClassName,
    showCloseButton,
    disableFocusTrap,
}: Props) {


    // Handle escape key
    useEffect(() => {
        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onEscape?.();
            }
        }
        if (show) {
            document.addEventListener("keydown", handleEscape);
        }
        return () => {
            document.removeEventListener("keydown", handleEscape);
        }
    }, [show, onEscape])

    useEffect(() => {
        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onEscape?.();
            }
        }
        if (show) {
            document.addEventListener("keydown", handleEscape);
        }
        return () => {
            document.removeEventListener("keydown", handleEscape);
        }
    }, [show, onEscape])

    const modalRef = useRef<HTMLDivElement>(null);


    const transitions = useTransition([{
        show,
        children,
    }], {
        from: { opacity: 0, transform: "translateY(-20px)" },
        enter: { opacity: 1, transform: "translateY(0px)" },
        leave: {
            opacity: 0, transform: "translateY(-20px)"
        },
        config: {
            duration: 300,
            // mass: 5,
            // friction: 120,
            // tension: 120,
            easing: easings.easeOutCubic

        },
        key: show,
    });


    useEffect(() => {
        if (modalRef.current) {
            if (show) {
                disableBodyScroll(modalRef.current);
            } else {
                enableBodyScroll(modalRef.current);
            }
        }
    }), ([show])

    const modalContent = transitions((style, item) => {
        return item.children && item.show && (
            <animated.div role="dialog"
                style={{
                    zIndex: 100,
                    opacity: style.opacity,
                }}
                className={clsx(styles.modalBackdrop, className)}
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        onBackgroundClick?.();
                    }
                }}
                ref={modalRef}
            >
                <animated.div
                    style={{
                        transform: style.transform
                    }}
                    className={clsx(styles.modalWrapper, wrapperClassName)}>
                    {showCloseButton && <div onClick={onCloseClick} className={styles.closeButton} >
                     <FaXmark />
                    </div>}
                    {item.children}
                </animated.div>
            </animated.div>
        )
    })

    if (disableFocusTrap) {
        return (
            <ModalPortal>
                {modalContent}
            </ModalPortal>
        )
    }
    else {
        return (
            <ModalPortal>
                <FocusTrap active={
                    show
                }>
                    <div>
                        {modalContent}
                    </div>
                </FocusTrap>
            </ModalPortal>
        )
    }
}
